#root {
  width: 100vw;
  height: 100vh;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  /*background-image: radial-gradient(rgba(255, 255, 255, 0.2) 20%, rgba(219, 219, 219, 0.1) );*/
  /*box-shadow: inset 0 0 50px floralwhite;*/
}

.App {
  text-align: center;
  /*display: flex;*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: auto;
}

.logo-wrapper {
  width: 100%;
  height: 40vh;
  margin-top: 10px;
}

.logo {
  height: 100%;
  object-fit: contain;
}

.description {
  margin: 5px 0 5px 0;
  width: 75%;
}

.description p {
  color: slategray;
  font-weight: 400;
}

.description h2 {
  margin: 5px 0 5px 0;
}

.shadow-wrapper {
  position: absolute;
  left: 10%;
  height: 100vh;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  .logo-wrapper {
    height: 30vh;
  }

  .description {
    margin: 10px 0 10px 0;
    width: 90%;
  }
}



@media only screen and (max-height: 675px) {
  .logo-wrapper {
    height: 30vh;
  }

  .description p {
    color: slategray;
    font-weight: 400;
    font-size: 12px;
  }

  .description h2 {
    margin: 5px 0 5px 0;
    font-size: 14px;
  }
}