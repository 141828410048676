.legal {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
}

.legal-logo-wrapper {
    width: 100%;
    height: 100px;
    margin: 20px auto 20px auto;
    text-align: center;
}

.segment-toggle {
    display: flex;
    color: slategrey;
    border-radius: 5px;
    border: solid 3px mediumaquamarine;
    cursor: pointer;
    text-align: center;
}

.segment-toggle div {
    padding: 5px 10px 5px 10px;
    font-weight: 700;
    font-size: 24px
}

.segment-toggle div.selected{
    background-color: mediumaquamarine;
    color: white;
}

@media only screen and (max-width: 500px) {
    .segment-toggle {
        flex-direction: column;
    }
}