.privacy {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin: auto;
    color: slategrey;
}

.section-title {
    color: mediumaquamarine;
}