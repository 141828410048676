.mailing-wrapper {
    width: 100%;
    margin: 5px 0 5px 0;
}

.mailing-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mailing-form input {
    width: 250px;
    height: 50px;
    font-size: 16px;
    outline: none;
    border: none;
    border-bottom: solid lightgray 3px;
    text-align: center;
    margin: 5px;
}

.mailing-form input:hover, .mailing-form input:focus, .mailing-form input:active {
    border-bottom-color: mediumaquamarine;
}
.mailing-form button {
    margin: 20px;
    width: 250px;
    height: 50px;
    outline: 0;
    border: none;
    background-color: slategray;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
}

.mailing-form button.button-ready {
    background-color: mediumaquamarine;
    cursor: pointer;
}

.mailing-form button.button-ready:hover {
    color: mediumaquamarine;
    background-color: white;
    border: solid 3px mediumaquamarine;
}

.input-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check {
    position: absolute;
    right: -25px;
    /*display: inline-block;*/
    transform: rotate(45deg);
    height: 16px;
    width: 8px;
    border-bottom: 4px solid #78b13f;
    border-right: 4px solid #78b13f;
}

.mailing-success {
    font-size: 14px;
    font-weight: 400;
    color: slategray;
}

@media only screen and (max-height: 600px) {
    .mailing-form input {
        width: 200px;
        height: 30px;
        font-size: 14px;
    }

    .mailing-form button {
        width: 175px;
        height: 30px;
        font-size: 14px;
        font-weight: 500;
    }
}