.showcase-wrapper {
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-family: Ubuntu;
}

.lace {
    background-color: whitesmoke;
    color: slategrey;
}

.slate {
    background-color: slategrey;
    color: whitesmoke;
}

.section-container {
    height: 70vh;
}

.half-wrapper {
    width: 50%;
    height: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    overflow: hidden;
}

.half-wrapper.right {
    text-align: right;
}

.showcase-spacer {
    height: 5px;
    width: 50%;
    border-radius: 10px;
    background-color: mediumaquamarine;
}

.half-wrapper.right .showcase-spacer {
    margin-left: auto;
    margin-right: 0;
}

.showcase-section, .showcase-header {
    width: 75%;
}

.showcase-header h1 {
    width: 100%;
    font-size: 56px;
}

.showcase-header p {
    width: 100%;
    font-size: 24px;
}

.mockup-pagination-wrapper {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: flex-end;
}

.mockup-button-wrapper {
    /*flex: 1;*/
    margin-left: 25px;
    text-align: center;

}

.mockup-button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: slategrey;
    display: inline-block;
}

.mockup-button.active {
    background-color: mediumaquamarine;
}

.mockup-button.left {
    float: left;
}

.mockup-button.right {
    float: right;
}

.mockups-row {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: scroll 500ms ease-in-out;
    white-space: nowrap;
}

.mockup-row-cell {
    width: 100%;
    height: 100%;
    line-height: 100%;
    display: inline-block;
    text-align: center;
}

.mockup-img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mockup {
    max-height: 80%;
    margin: 30px;
    z-index: 1;
}

.corner-logo {
    position: absolute;
    top: -150px;
    left: -150px;
    height: 500px;
}

.footer-wrapper {
    width: 100vw;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-half {
    flex: 1;
    justify-content: center;
    align-items: center;
}



.footer-wrapper a {
    text-decoration: none;
    color: mediumaquamarine;
}

@media only screen and (max-width: 750px) {
    .showcase-wrapper {
        max-height: 60vh;
    }
}

@media only screen and (max-width: 575px) {
    .showcase-wrapper {
        flex-direction: column;
        max-height: 120vh;
        min-height: 120vh;
    }

    .showcase-spacer, .half-wrapper.right .showcase-spacer {
        margin-left: auto;
        margin-right: auto;
    }

    .mockup {
        max-height: 50vh;
        max-width: 25vh;
        margin: 15px;
    }

    .half-wrapper, .half-wrapper.right {
        width: 100%;
        text-align: center;
        height: 100%;
        padding: 15px;
    }

    .footer-wrapper {
        flex-direction: column;
    }

    .mockup-pagination-wrapper {
        justify-content: center;
    }

    .mockup-button-wrapper {
        flex: 1;
        margin: 15px;
        text-align: center;
    }
}