.background-wrapper {
    opacity: 65%;
    z-index: -5;
    top: 0;
    background: linear-gradient(135deg, #78b13f 5%, #f3b3bb 26%, #d9ebe0 54%, #ebf9e3 78%, #f4f8d0 100%);
}

#Clouds {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 10px;
    height: 35%;
    overflow: hidden;
    -webkit-animation: FadeIn 3s ease-out;
    animation: FadeIn 3s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@-webkit-keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.Cloud {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 10px;
    -webkit-animation-duration: 120s;
    animation-duration: 120s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: Float, FadeFloat;
    animation-name: Float, FadeFloat;
    z-index: 1;
}
.Cloud.Foreground {
    height: 10%;
    min-height: 20px;
    z-index: 3;
}
.Cloud.Background {
    height: 9.09090909%;
    min-height: 8px;
    -webkit-animation-duration: 210s;
    animation-duration: 210s;
}
@-webkit-keyframes Float {
    from {
        -webkit-transform: translateX(100%) translateZ(0);
        transform: translateX(100%) translateZ(0);
    }
    to {
        -webkit-transform: translateX(-15%) translateZ(0);
        transform: translateX(-15%) translateZ(0);
    }
}
@keyframes Float {
    from {
        -webkit-transform: translateX(100%) translateZ(0);
        transform: translateX(100%) translateZ(0);
    }
    to {
        -webkit-transform: translateX(-15%) translateZ(0);
        transform: translateX(-15%) translateZ(0);
    }
}
/*
@keyframes Float {
  from { transform: translateX(100%) translateY(-100%) translateZ(0); }
  50% { transform: translateX(55%) translateY(0) translateZ(0); }
  to { transform: translateX(-5%) translateY(-100%) translateZ(0); }
}
*/
@-webkit-keyframes FadeFloat {
    0%,
    100% {
        opacity: 0;
    }
    5%,
    90% {
        opacity: 1;
    }
}
@keyframes FadeFloat {
    0%,
    100% {
        opacity: 0;
    }
    5%,
    90% {
        opacity: 1;
    }
}

.Cloud:nth-child(13) {
    animation-delay: -165.61538462s;
    top: 55%;
}

.Cloud:nth-child(13) {
    animation-delay: -195.61538462s;
    top: 30%;
}

.Cloud:nth-child(12) {
    animation-delay: -50.61538462s;
    top: 60%;
}

.Cloud:nth-child(12) {
    animation-delay: 100s;
    top: 10%;
}

.Cloud:nth-child(12) {
    animation-delay: 40s;
    top: 35%;
}

.Cloud:nth-child(11) {
    animation-delay: -255.61538462s;
    top: 20%;
}

.Cloud:nth-child(11) {
    animation-delay: -100.61538462s;
    top: 10%;
}
.Cloud.Foreground:nth-child(10) {
    animation-duration: 80s;
    height: 35%;
}
.Cloud.Background:nth-child(10) {
    animation-duration: 110s;
    height: -3.40909091%;
}
.Cloud:nth-child(9) {
    animation-delay: -166.15384615s;
    top: 54%;
}
.Cloud.Foreground:nth-child(9) {
    -webkit-animation-duration: 84s;
    animation-duration: 84s;
    height: 32.5%;
}
.Cloud.Background:nth-child(9) {
    -webkit-animation-duration: 114s;
    animation-duration: 114s;
    height: -2.15909091%;
}
.Cloud:nth-child(8) {
    -webkit-animation-delay: -147.69230769s;
    animation-delay: -147.69230769s;
    top: 48%;
}
.Cloud.Foreground:nth-child(8) {
    -webkit-animation-duration: 88s;
    animation-duration: 88s;
    height: 30%;
}
.Cloud.Background:nth-child(8) {
    -webkit-animation-duration: 118s;
    animation-duration: 118s;
    height: -0.90909091%;
}
.Cloud:nth-child(7) {
    -webkit-animation-delay: -129.23076923s;
    animation-delay: -129.23076923s;
    top: 42%;
}
.Cloud.Foreground:nth-child(7) {
    -webkit-animation-duration: 92s;
    animation-duration: 92s;
    height: 27.5%;
}
.Cloud.Background:nth-child(7) {
    -webkit-animation-duration: 122s;
    animation-duration: 122s;
    height: 0.34090909%;
}
.Cloud:nth-child(6) {
    -webkit-animation-delay: -110.76923077s;
    animation-delay: -110.76923077s;
    top: 36%;
}
.Cloud.Foreground:nth-child(6) {
    -webkit-animation-duration: 96s;
    animation-duration: 96s;
    height: 25%;
}
.Cloud.Background:nth-child(6) {
    -webkit-animation-duration: 126s;
    animation-duration: 126s;
    height: 1.59090909%;
}
.Cloud:nth-child(5) {
    -webkit-animation-delay: -92.30769231s;
    animation-delay: -92.30769231s;
    top: 30%;
}
.Cloud.Foreground:nth-child(5) {
    -webkit-animation-duration: 100s;
    animation-duration: 100s;
    height: 22.5%;
}
.Cloud.Background:nth-child(5) {
    -webkit-animation-duration: 130s;
    animation-duration: 130s;
    height: 2.84090909%;
}
.Cloud:nth-child(4) {
    -webkit-animation-delay: -73.84615385s;
    animation-delay: -73.84615385s;
    top: 24%;
}
.Cloud.Foreground:nth-child(4) {
    -webkit-animation-duration: 104s;
    animation-duration: 104s;
    height: 20%;
}
.Cloud.Background:nth-child(4) {
    -webkit-animation-duration: 134s;
    animation-duration: 134s;
    height: 4.09090909%;
}
.Cloud:nth-child(3) {
    -webkit-animation-delay: -55.38461538s;
    animation-delay: -55.38461538s;
    top: 18%;
}
.Cloud.Foreground:nth-child(3) {
    -webkit-animation-duration: 108s;
    animation-duration: 108s;
    height: 17.5%;
}
.Cloud.Background:nth-child(3) {
    -webkit-animation-duration: 138s;
    animation-duration: 138s;
    height: 5.34090909%;
}
.Cloud:nth-child(2) {
    -webkit-animation-delay: -36.92307692s;
    animation-delay: -36.92307692s;
    top: 12%;
}
.Cloud.Foreground:nth-child(2) {
    -webkit-animation-duration: 112s;
    animation-duration: 112s;
    height: 15%;
}
.Cloud.Background:nth-child(2) {
    -webkit-animation-duration: 142s;
    animation-duration: 142s;
    height: 6.59090909%;
}
.Cloud:nth-child(1) {
    -webkit-animation-delay: -18.46153846s;
    animation-delay: -18.46153846s;
    top: 6%;
}
.Cloud.Foreground:nth-child(1) {
    -webkit-animation-duration: 116s;
    animation-duration: 116s;
    height: 12.5%;
}
.Cloud.Background:nth-child(1) {
    -webkit-animation-duration: 146s;
    animation-duration: 146s;
    height: 7.84090909%;
}

.Cloud::before {
    opacity: 0.4;
}
.Cloud {
    filter: invert(100%) sepia(20%) saturate(400%) hue-rotate(-40deg) brightness(150%) contrast(100%);
    background-image: url('../img/Cloud-Gradient@1x.png');
    opacity: 0.4;
    /*background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKQAAABgCAYAAACTzNnjAAAFCklEQVR42u3d34uVRRjA8YMsEi0iSwhdRBGhSJgZiNRFIkWhQVEXBipKUVBBLCF6k0h4UxFkBLq4QT/Qiyi80EgxCjXMWqOMtqy0bBNja92yXatN3c3pGc9sHU/v+X3emWfe93vx+QN23u+e95x5Z94pmNWFQo5NEytErzgoBsSIOCf+FqbEBTEhzophcVTsFxvFEjE152PZHGMukbcB6BAPij0uqgtl0bXCBvyjeFPcS2wEWc0y0ec++Ywnf7rwbyc8grQ6RY8Y9RhhJT+Jde4TmghzFuR08bo4ryDEpE/N58QUYsx+kFPcD4yzCkMsZz+1uwkyu0HeKYYiCLHcN2I2QWYnSPudbHubfy37NuFu4wQZeZD2k2Uw4hDLHRZdBBnnH3K/5ykcX86IeQQZl6civ0XXYv/RlhJkHLZkOMTypz7dBKnbyzmJsfTZeTdB6vRszmIsjXI5QeryWMa/M9ZinzjdTJA63CLGcxzjpD/ElQQZfq3iKDFe8lSHIAPqI8L/6SHIMB4nvorTQQsI0v+teoz4KjpBkH7tIrqaNhCkH9cnbK5C8q/uywiSHzKabCLIdM3K+QR4o8YysfVWcZDvElnD1hNkOi5XuilLu5MlOyzni3vESnGfWCRuIMjm1zgSWPM7GWvNXdonXkdM8Q0dCwmytu8Iy/vK9B3iRoJMnghnqifc8jb7yXkXQf5nLWGo2WR2HUGuLrxPDKq25D6d9yBPE4I6/e6rVO6CnMpkuFq/eLmFKwtyMRde/dOg2XkKkvlH/X5P9ZNSWZBvcMGjMJza6iJlQe7nYkfjozwE+TkXOiprsx7kES5yVOzLYK/IcpA/cJGj806Wg/yaCxzlzsersxpkPxc4SruzGuQBLm603yU7DPOQUOSJWIK0E6j2lcvPm+JJVp+674oDbiFuv5t/fEXs5cJG65DmIO3KY3vG3yCLbXO1N1xVkB3uWfQQFye3rtUS5DN1bC5C9j0cOki7XOwUFwJOb6gg7TmCW1lMizI7QwQ5nW2qqGCv7yDtwsxfGXhU0OczyKvECIOOKvb5CnKa2+TDoKOat3wFyaIH1ONFH0H2MNCo00NpBzmXR39owIy0gzzOIKNOv6X9LHsZg4wGp3xsM/ZMoUfF3aaZ4++qBDnIIKMNzrsHKb11Lb6oEORtDCRSYB81f+vWxzYUJBv2kTYb5vx6gzzHgMHTjsUXagV5BwMFzz78d5NYQpDbGCAEcOzissaEIL9kcBDIx0lBsrwMIW0uD3KcQUHgqaEFpUGyLQGhnSgNkgGBBg8QJDQ5Phkky82g5bvkNQXDscDQY6MNcpiBgBL9NsjDDAS0LPq1Qb7EQECJicLFSUkGAkpMLgMaYzCgKci3GQxomPqZDPImBgMa9uOULifnDWcI7VRpkIsYEAR2sHwX2GcMCgJak/QKPh4lIsgcpOhM2iv7JIODAPqqvY7vPQYInlf6zKsWpN2iOMBAwZMD9bzSudPwvh+kr3gofJ0vve/ikxIp36pXNHosiD2j5gMGD2ksyG3lJK917qc5A4l2eNW04Wi5OaZ41DADilZu0xtMmw/fXCV+ZnDRoFFTfBdpaudlLxVfsXMRNYy7W3SH8XSAu/01bo8s/kL8xQWAM+JC7DIeDnCvZqZ4RGxxi3/3iUPOJ+5TtRXfG/v6jeadNMVjlqsZquG0uwU164ybg2vFuPuh2ax23tkm3N91VLwmbjVNvPT+H7Ro4730ITNPAAAAAElFTkSuQmCC);*/
}
.Cloud.Background {
    background-image: url('../img/Cloud@1x.png');
    opacity: 0.4;
    /*background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAAAqCAYAAAAUJM0rAAACFUlEQVRo3u3aPSwDYRzH8UZEbAYiYrDYjLZGpIPBJLGwSKwi0k1iYBKDdFJsEiMxSZgsFZJGiGjqJWEhSKuaNKRUG8r5PfI8SXOud72+PH2eu2f47Ndv7+V5/nee/G6bh5MWmIV9iEMWvkGjfiADD7AHk9BU6+PSNK0kPAINwwXkC6KU6gvC4HVyKB/clhHHCDnbTqHbSaEaYIv+OK3KyBk274RQrXBXg0B6B/QPkTIUiZTiEIm5gkbZQjXTJ5nG2ZFsocJ1iMQEZQk1VcdIGl2L9Yoeitwj3uocirgRPVRAgEjMoMihkgKFStIzK063QVHYhKF6h/IKFMlKGpaNlhM8Qq1JFIp5gQHeoSIShmJPST/PUElJQ7FN9hivUGmJQxE56OQRKiN5qL/NNY9QKQeEIpdgR7VC9cEqHeGeUyEHXHrMUiWhyKx6BV4dEsNMtNxQI4Ls33h5LidUoEYjXKEXoXZDLbgsEBOzE8qne8fmJhE7oWIujUQcwhyMmy0VSKRRF0cyWlclYMYo1LEKZOgRugpDpVWUot5ZLI8LlwN23bNQKoY1vwpVmmsS6kOFsPRJQl2qENZIqGkVwnp9xb5nUksEcxm2hZlQMcy3OYXTgx0VpOi2pl8/jwqpMP9sF5twBl08ctE7I/dws5l5D5y4OBj51HvdzluYdlik0RL06cjkKpSlB1Span5x/AQb5Hfrxyy/oU5ISeVw53AAAAAASUVORK5CYII=);*/
}
html,
body {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
}
body {
    min-height: 100%;
}
body {
    color: #FFF;
    background-color: white;
    /*background-image: radial-gradient(circle, #F9A72B 0%, #FA9026 70%, #FB6C1F 100%);*/
}

#Sun {

}
